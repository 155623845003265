<template>
  <div class="p-2">
    <h1 class=" text-text2 heading-2">Shipworks 90 Days</h1>
  </div>
</template>
<script>
export default {
  components: {
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Shipping ◾ Amazon Shipworks'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>